/* ./src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.tick {
  stroke-dasharray: 17;
  stroke-dashoffset: 17;
  animation: draw 0.5s forwards; /* Adjust duration as necessary */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

/* Keyframes for drawing the checkmark */
@keyframes draw-check {
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: 100, 0;
  }
}

/* Styling for the checkmark path */
.drawn-checkmark {
  stroke: #ffffff; /* Color of the checkmark */
  stroke-width: 4;
  fill: transparent;
  stroke-dasharray: 0, 100;
  animation: draw-check 0.8s ease forwards; /* Apply the animation */
}
